import { connect } from 'react-redux'
import AdminActions from "../components/AdminActions";
import {fetchAdminTab, manageAdminAction} from "../actions/adminActions";

const mapStateToProps = state => ({
    adminActions: state.adminActions,
});

const mapDispatchToProps = dispatch => ({
    fetchAdminTab: () => dispatch(fetchAdminTab()),
    manageAction: (actionId, action) => () => dispatch(manageAdminAction(actionId, action)),
});


export default connect(mapStateToProps, mapDispatchToProps)(AdminActions)
