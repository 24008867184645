import React from 'react';
import '../css/App.css';
import {Button} from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';

const Crisis = ({ wakeUpAriel }) =>
    <div>
        <Button onClick={wakeUpAriel}>Wake up Ariel</Button>
    </div>;

export default Crisis;
