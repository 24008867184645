import { connect } from 'react-redux'
import Main from "../components/Main";
import {changeTab} from "../actions/nav";
import {dismissToast} from "../actions/main";

const mapStateToProps = state => ({
    nav: state.nav,
    toastIsActive: state.main.toastIsActive,
    toastContent: state.main.toastContent,
});

const mapDispatchToProps = dispatch => ({
    handleTabSelect: index => dispatch(changeTab(index)),
    onDismissToast: () => dispatch(dismissToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main)
