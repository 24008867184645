import React, {useCallback, useState} from 'react';
import {Banner, Button, Caption, DropZone, Icon, List, Stack, Thumbnail} from "@shopify/polaris";
import {CancelSmallMinor} from '@shopify/polaris-icons';
import fetch from 'cross-fetch';
import {fetchApi, fetchToken} from "../actions/main";

const FileUpload = ({presignedUrl, uploadSuccess, uploadFailure, dropZoneAccepts, dropZoneType, fileCheckFunction}) => {
    const getSignedUrl = async () => {
        setLoading(true);
        const token = await fetchToken();
        const res = await fetchApi(presignedUrl, {token, file_name: file.name});
        if (res.ok) {
          const json = await res.json();
          await uploadFile(json);
        } else {
          setLoading(false);
          console.log(res);
        }
    }

    const uploadFile = async json => {
        const res = await fetch(json.url,
            {
                method: 'put',
                headers: {
                    'Content-Type': file.type,
                    'Cache-Control': 'max-age=0',
                },
                body: file,
            });
        setLoading(false);
        if (res.ok) {
            uploadSuccess(res, json);
        } else {
            uploadFailure(res);
        }
    }

    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    const hasError = rejectedFiles.length > 0;
    const handleDrop = useCallback(
        (_droppedFiles, acceptedFiles, rejectedFiles) => {
            let acceptedFile = acceptedFiles[0];
            if (acceptedFile && !fileCheckFunction(acceptedFile)) {
              setRejectedFiles([acceptedFile]);
              return;
            }
            setFile(acceptedFile);
            setRejectedFiles(rejectedFiles);
        },
        [fileCheckFunction],
    );
    const fileUpload = !file && <DropZone.FileUpload/>;
    const removeFile = (event) => {
        setFile(undefined);
        event.stopPropagation();
    };
    const uploadedFile = file && (
        <Stack alignment="center">
          {dropZoneType === 'image' && <Thumbnail
                size="large"
                alt={file.name}
                source={window.URL.createObjectURL(file)}
            />}
            <div>
                {file.name} <Caption>{file.size} bytes</Caption>
                <span style={{cursor: 'pointer'}} onClick={removeFile}><Icon source={CancelSmallMinor}/></span>
            </div>
        </Stack>

    );

    const errorMessage = hasError && (
        <Banner
            title="The following images couldn’t be uploaded:"
            status="critical"
        >
            <List type="bullet">
                {rejectedFiles.map((file, index) => (
                    <List.Item key={index}>
                      {fileCheckFunction(file) ? `"${file.name}" is not supported. File type must be ${dropZoneAccepts}` : 'Bad file. Are you sure this is the right file?'}
                    </List.Item>
                ))}
            </List>
        </Banner>
    );

    return (
        <Stack vertical>
            {errorMessage}
            <DropZone accept={dropZoneAccepts} type={dropZoneType} onDrop={handleDrop}>
                {uploadedFile}
                {fileUpload}
            </DropZone>
            <div>
            {file && <span style={{marginLeft:'10px'}}><Button primary onClick={getSignedUrl} loading={loading}>Upload</Button></span>}
            </div>
        </Stack>
    );
};

export default FileUpload;
