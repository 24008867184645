import * as _ from 'lodash';
import {
    SET_ADMIN_ACTION_ID,
    SET_ADMIN_ACTION_LOADING,
    SET_ADMIN_TAB,
    SET_ADMIN_TAB_INITIALIZING
} from "../actions/adminActions";

const adminActions = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case SET_ADMIN_ACTION_ID:
            newState = _.cloneDeep(state);
            newState.actionId = action.id;
            break;
        case SET_ADMIN_TAB:
            newState = _.cloneDeep(state);
            newState.permitted = action.json.permitted;
            newState.adminActions = _.sortBy(_.cloneDeep(action.json.admin_actions), 'updated_at').reverse();
            newState.actions = _.cloneDeep(action.json.actions);
            break;
        case SET_ADMIN_ACTION_LOADING:
            newState = _.cloneDeep(state);
            {
                const adminAction = _.find(newState.adminActions, {id: action.id});
                adminAction.isLoading = action.bool;
            }
            break;
        case SET_ADMIN_TAB_INITIALIZING:
            newState = _.cloneDeep(state);
            newState.initializing = action.bool;
            break;
        default:
            return state;
    }
    return newState;
};

export default adminActions;
