import {combineReducers} from "redux";
import main from "./main";
import stickyAtc from "./stickyAtc";
import nav from "./nav";
import freeShippingBar from "./freeShippingBar";
import crossApp from "./crossApp";
import adminActions from "./adminActions";
import permissions from "./permisssions";

export default combineReducers({
    main,
    stickyAtc,
    nav,
    freeShippingBar,
    crossApp,
    adminActions,
    permissions,
})
