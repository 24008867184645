import { connect } from 'react-redux'
import FreeShippingBar from "../components/FreeShippingBar";
import {
    freeShippingBarActuallyModifyNum,
    freeShippingBarCreateStoreJs,
    freeShippingBarModifyBool, freeShippingBarModifyNum,
    freeShippingBarSearchShop,
    freeShippingBarShopNameChanged
} from "../actions/freeShippingBar";

const mapStateToProps = state => ({
    freeShippingBar: state.freeShippingBar,
});

const mapDispatchToProps = dispatch => ({
    onShopNameChanged: text => dispatch(freeShippingBarShopNameChanged(text)),
    onSearch: () => dispatch(freeShippingBarSearchShop()),
    createStoreJs: () => dispatch(freeShippingBarCreateStoreJs()),
    modifyBoolean: attribute => bool => dispatch(freeShippingBarModifyBool(attribute, bool)),
    onNumberChange: attribute => num => dispatch(freeShippingBarActuallyModifyNum(attribute, num)),
    onSaveNumber: attribute => () => dispatch(freeShippingBarModifyNum(attribute)),
});


export default connect(mapStateToProps, mapDispatchToProps)(FreeShippingBar)
