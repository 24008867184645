import * as _ from 'lodash';
import {CROSS_APP_CHANGE_NUMBER} from "../actions/crossApp";

const crossApp = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case CROSS_APP_CHANGE_NUMBER:
            newState = _.cloneDeep(state);
            newState[action.field] = Number(action.text);
            break;
        default:
            return state;
    }
    return newState;
};

export default crossApp;
