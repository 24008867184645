import { connect } from 'react-redux'
import {
     wakeUpAriel
} from "../actions/main";
import Crisis from "../components/Crisis";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    wakeUpAriel: () => dispatch(wakeUpAriel()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Crisis)
