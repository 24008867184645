import * as _ from 'lodash';
import {
    EDIT_USER_PERMISSIONS,
    PERMISSIONS_TOGGLE_SHOW_ONLY_ACTIVE,
    SET_PERMISSIONS_TAB,
    SET_PERMISSIONS_TAB_INITIALIZING, SET_USER_PERMISSIONS_LOADING, TOGGLE_PERMISSION
} from "../actions/permissions";

const permissions = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case SET_PERMISSIONS_TAB_INITIALIZING:
            newState = _.cloneDeep(state);
            newState.initializing = action.bool;
            break;
        case SET_PERMISSIONS_TAB:
            newState = _.cloneDeep(state);
            newState.permitted = action.json.permitted;
            newState.adminUsers = action.json.admin_users;
            newState.permissions = action.json.permissions;
            newState.showOnlyActive = true;
            break;
        case PERMISSIONS_TOGGLE_SHOW_ONLY_ACTIVE:
            newState = _.cloneDeep(state);
            newState.showOnlyActive = !newState.showOnlyActive;
            break;
        case EDIT_USER_PERMISSIONS:
            newState = _.cloneDeep(state);
            newState.adminUsers.forEach(u => u.isEditingPermissions = u.id === action.id);
            break;
        case SET_USER_PERMISSIONS_LOADING:
            newState = _.cloneDeep(state);
            {
                const user = _.find(newState.adminUsers, {id: action.id});
                user.isLoadingPermission = action.bool;
            }
            break;
        case TOGGLE_PERMISSION:
            newState = _.cloneDeep(state);
            {
                const user = _.find(newState.adminUsers, {id: action.id});
                user.permissions = user.permissions || {};
                user.permissions[action.permission] = !user.permissions[action.permission];
            }
            break;
        default:
            return state;
    }
    return newState;
};

export default permissions;
