import * as _ from 'lodash';
import {
    ACTIVATE_TOAST,
    ADDING_SCRIPT_TAG,
    CREATE_STORE_JS_SUCCESS,
    CREATING_STORE_JS,
    DISMISS_TOAST,
    GENERATE_PARTNER_LINK_ERROR,
    GENERATE_PARTNER_LINK_SUCCESS,
    GETTING_ALL_STORES,
    GETTING_ALL_STORES_ERROR,
    INSTALL_SCRIPTS_SUCCESS,
    INSTALLING_SCRIPTS,
    MONEY_CSS_CLASS_CHANGE,
    NUMBER_CHANGE,
    ON_SHOP_NAME_CHANGED,
    OPEN_SEND_REVIEW_REQUEST,
    PACKAGE_CHANGE_ERROR,
    PACKAGE_CHANGE_SUCCESS,
    PARTNER_EMAIL_CHANGE,
    REMOVING_SCRIPT_TAG,
    RESYNC,
    RESYNC_SUCCESS,
    REVIEW_REQUEST_EMAIL_CHANGE,
    SEARCH_ERROR,
    SEARCH_SUCCESS,
    SEARCHING,
    SEND_REVIEW_REQUEST_FAILURE,
    SEND_REVIEW_REQUEST_SUCCESS,
    SENDING_REVIEW_REQUEST, SET_DISCOUNT_VERSION,
    SET_PACKAGE_PROCESSING,
    SET_STORES, SET_WIDGET_DEV, SET_WIDGET_VERSION, SET_WIDGET_VERSION_LOCK,
    SUBMIT_SETTINGS_SUCCESS,
    SUBMITTING_SETTINGS,
    TOGGLE_ATTRIBUTES,
    TOGGLE_BOOL_PROCESSING,
    TOGGLE_BOOL_SUCCESS, TOGGLE_GEN_SETTINGS_BOOL_SUCCESS,
    TOGGLE_NUMBER_ERROR,
    TOGGLE_NUMBER_PROCESSING,
    TOGGLE_NUMBER_SUCCESS, TOGGLE_WIDGET_DEV_PROCESSING,
    WAKE_UP_ARIEL_FAILURE,
    WAKE_UP_ARIEL_SUCCESS, WIDGET_VERSION_CHANGING
} from "../actions/main";

const main = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case GETTING_ALL_STORES:
            newState = _.cloneDeep(state);
            newState.gettingAllStores = true;
            break;
        case GETTING_ALL_STORES_ERROR:
            newState = _.cloneDeep(state);
            newState.gettingAllStores = false;
            newState.error = 'Error getting stores';
            break;
        case SET_STORES:
            newState = _.cloneDeep(state);
            newState.gettingAllStores = false;
            newState.stores = action.shopNames.map(s => ({key: s, value: s}));
            newState.booleans = _.cloneDeep(action.booleans);
            newState.modifiableBooleans = _.cloneDeep(action.modifiableBooleans);
            newState.modifiableNumbers = _.cloneDeep(action.modifiableNumbers);
            newState.packages = _.cloneDeep(action.packages);
            newState.numberError = {};
            break;
        case ON_SHOP_NAME_CHANGED:
            newState = _.cloneDeep(state);
            newState.shopName = action.text;
            newState.adminLink = null;
            newState.bundlesAdminLink = null;
            break;
        case SEARCHING:
            newState = _.cloneDeep(state);
            newState.searching = true;
            newState.searchError = false;
            newState.adminLink = false;
            newState.bundlesAdminLink = false;
            newState.addingScriptTag = false;
            newState.removingScriptTag = false;
            break;
        case SEARCH_ERROR:
            newState = _.cloneDeep(state);
            newState.searching = false;
            newState.searchError = true;
            newState.boolProcessing = false;
            newState.numberProcessing = false;
            newState.resyncing = false;
            newState.installingScripts = false;
            newState.addingScriptTag = false;
            newState.removingScriptTag = false;
            newState.creatingStoreJs = false;
            newState.isSubmitting = false;
            break;
        case TOGGLE_ATTRIBUTES:
            newState = _.cloneDeep(state);
            newState.showAttributes = !newState.showAttributes;
            break;
        case TOGGLE_BOOL_PROCESSING:
            newState = _.cloneDeep(state);
            newState.boolProcessing = true;
            break;
        case TOGGLE_NUMBER_PROCESSING:
            newState = _.cloneDeep(state);
            newState.numberProcessing = true;
            break;
        case SET_PACKAGE_PROCESSING:
            newState = _.cloneDeep(state);
            newState.packageChanging = true;
            break;
        case ACTIVATE_TOAST:
            newState = _.cloneDeep(state);
            newState.toastContent = action.text;
            newState.toastIsActive = true;
            break;
        case PACKAGE_CHANGE_SUCCESS:
            newState = _.cloneDeep(state);
            newState.packageChanging = false;
            newState.toastContent = 'Success';
            newState.toastIsActive = true;
            newState.attributes.package_id = action.id;
            break;
        case PACKAGE_CHANGE_ERROR:
            newState = _.cloneDeep(state);
            newState.packageChanging = false;
            newState.toastContent = 'Error';
            newState.toastIsActive = true;
            break;
        case RESYNC:
            newState = _.cloneDeep(state);
            newState.resyncing = true;
            break;
        case SUBMITTING_SETTINGS:
            newState = _.cloneDeep(state);
            newState.isSubmitting = true;
            break;
        case INSTALLING_SCRIPTS:
            newState = _.cloneDeep(state);
            newState.installingScripts = true;
            break;
        case ADDING_SCRIPT_TAG:
            newState = _.cloneDeep(state);
            newState.addingScriptTag = true;
            break;
        case REMOVING_SCRIPT_TAG:
            newState = _.cloneDeep(state);
            newState.removingScriptTag = true;
            break;
        case CREATING_STORE_JS:
            newState = _.cloneDeep(state);
            newState.creatingStoreJs = true;
            break;
        case INSTALL_SCRIPTS_SUCCESS:
            newState = _.cloneDeep(state);
            newState.installingScripts = false;
            newState.toastContent = 'Installing scripts...';
            newState.toastIsActive = true;
            break;
        case CREATE_STORE_JS_SUCCESS:
            newState = _.cloneDeep(state);
            newState.creatingStoreJs = false;
            newState.toastContent = 'Creating store js...';
            newState.toastIsActive = true;
            break;
        case RESYNC_SUCCESS:
            newState = _.cloneDeep(state);
            newState.resyncing = false;
            newState.toastContent = 'Hard refreshing...';
            newState.toastIsActive = true;
            break;
        case SUBMIT_SETTINGS_SUCCESS:
            newState = _.cloneDeep(state);
            newState.isSubmitting = false;
            newState.toastContent = 'Saved settings';
            newState.toastIsActive = true;
            break;
        case SEND_REVIEW_REQUEST_SUCCESS:
            newState = _.cloneDeep(state);
            newState.sendingReviewRequest = false;
            newState.toastContent = 'Email sent';
            newState.toastIsActive = true;
            break;
        case SEND_REVIEW_REQUEST_FAILURE:
            newState = _.cloneDeep(state);
            newState.sendingReviewRequest = false;
            newState.toastContent = 'Failed to send email';
            newState.toastIsActive = true;
            break;
        case GENERATE_PARTNER_LINK_ERROR:
            newState = _.cloneDeep(state);
            newState.toastContent = 'Failed to generate link';
            newState.toastIsActive = true;
            break;
        case WAKE_UP_ARIEL_SUCCESS:
            newState = _.cloneDeep(state);
            newState.toastContent = 'Waking up Ariel';
            newState.toastIsActive = true;
            break;
        case WAKE_UP_ARIEL_FAILURE:
            newState = _.cloneDeep(state);
            newState.toastContent = 'Failed to send call';
            newState.toastIsActive = true;
            break;
        case GENERATE_PARTNER_LINK_SUCCESS:
            newState = _.cloneDeep(state);
            newState.toastContent = 'Link copied';
            newState.toastIsActive = true;
            break;
        case DISMISS_TOAST:
            newState = _.cloneDeep(state);
            newState.toastIsActive = false;
            break;
        case OPEN_SEND_REVIEW_REQUEST:
            newState = _.cloneDeep(state);
            newState.showReviewRequestEmail = !newState.showReviewRequestEmail;
            if (newState.showReviewRequestEmail) {
                newState.reviewRequestEmail = newState.attributes.owner_email;
            }
            break;
        case REVIEW_REQUEST_EMAIL_CHANGE:
            newState = _.cloneDeep(state);
            newState.reviewRequestEmail = action.text;
            break;
        case PARTNER_EMAIL_CHANGE:
            newState = _.cloneDeep(state);
            newState.partnerEmail = action.text;
            break;
        case SENDING_REVIEW_REQUEST:
            newState = _.cloneDeep(state);
            newState.sendingReviewRequest = true;
            break;
        case MONEY_CSS_CLASS_CHANGE:
            newState = _.cloneDeep(state);
            newState.generalSettings.values.money_css_class = action.text;
            break;
        case TOGGLE_BOOL_SUCCESS:
            newState = _.cloneDeep(state);
            newState.boolProcessing = false;
            newState.attributes[action.key] = action.bool;
            break;
        case TOGGLE_GEN_SETTINGS_BOOL_SUCCESS:
            newState = _.cloneDeep(state);
            newState.boolProcessing = false;
            newState.generalSettings.values[action.key] = action.bool;
            break;
        case WIDGET_VERSION_CHANGING:
            newState = _.cloneDeep(state);
            newState.changingWidgetLockVersion = action.bool;
            break;
        case NUMBER_CHANGE:
            newState = _.cloneDeep(state);
            newState.attributes[action.key] = action.number;
            break;
        case SET_WIDGET_VERSION_LOCK:
            newState = _.cloneDeep(state);
            newState.attributes.widget_version_lock = action.text;
            break;
        case SET_DISCOUNT_VERSION:
            newState = _.cloneDeep(state);
            newState.attributes.update_discount_version = action.text;
            break;
        case TOGGLE_WIDGET_DEV_PROCESSING:
            newState = _.cloneDeep(state);
            newState.widgetDevProcessing = action.bool;
            break;
        case SET_WIDGET_DEV:
            newState = _.cloneDeep(state);
            newState.attributes.widget_dev = action.num;
            break;
        case TOGGLE_NUMBER_SUCCESS:
            newState = _.cloneDeep(state);
            newState.numberProcessing = false;
            newState.numberError[action.key] = false;
            newState.toastContent = `${action.key} set to ${newState.attributes[action.key]} successfully`;
            newState.toastIsActive = true;
            break;
        case TOGGLE_NUMBER_ERROR:
            newState = _.cloneDeep(state);
            newState.numberProcessing = false;
            newState.numberError[action.key] = true;
            break;
        case SET_WIDGET_VERSION:
            newState = _.cloneDeep(state);
            {
                const existingMajors = new Set(action.json.versions.map(v => v.version.split('.')[0]));
                newState.availableWidgetMajorVersions = [
                    {label: 'Latest'},
                    ...(new Array(...existingMajors).map(v => ({label: `v${v}`, value: v}))),
                ]
            }
            break;
        case SEARCH_SUCCESS:
            newState = _.cloneDeep(state);
            newState.searching = false;
            newState.searchError = false;
            newState.numberError = {};
            if (action.json.error) {
                newState.error = action.json.error;
                newState.adminLink = false;
                newState.bundlesAdminLink = false;
            } else {
                newState.error = false;
                newState.adminLink = action.json.admin_link;
                newState.bundlesAdminLink = action.json.bundles_admin_link;
                newState.attributes = _.cloneDeep(action.json.attributes);
                newState.generalSettings = _.cloneDeep(action.json.general_settings);
            }
            break;
        default:
            return state;
    }
    return newState;
};

export default main;
