import {activateToast, fetchApi, fetchToken} from "./main";

export const FREE_SHIPPING_BAR_GETTING_ALL_STORES = 'FREE_SHIPPING_BAR_GETTING_ALL_STORES';
export const FREE_SHIPPING_BAR_CREATING_STORE_JS = 'FREE_SHIPPING_BAR_CREATING_STORE_JS';
export const FREE_SHIPPING_BAR_MODIFYING_BOOL = 'FREE_SHIPPING_BAR_MODIFYING_BOOL';
export const FREE_SHIPPING_BAR_MODIFYING_NUM = 'FREE_SHIPPING_BAR_MODIFYING_NUM';
export const FREE_SHIPPING_BAR_ACTUALLY_MODIFY_BOOL = 'FREE_SHIPPING_BAR_ACTUALLY_MODIFY_BOOL';
export const FREE_SHIPPING_BAR_ACTUALLY_MODIFY_NUM = 'FREE_SHIPPING_BAR_ACTUALLY_MODIFY_NUM';
export const FREE_SHIPPING_BAR_SET_ALL_STORES = 'FREE_SHIPPING_BAR_SET_ALL_STORES';
export const FREE_SHIPPING_BAR_SEARCH_SUCCESS = 'FREE_SHIPPING_BAR_SEARCH_SUCCESS';
export const FREE_SHIPPING_BAR_SHOP_NAME_CHANGED = 'FREE_SHIPPING_BAR_SHOP_NAME_CHANGED';
export const SEARCHING_FREE_SHIPPING_BAR = 'SEARCHING_FREE_SHIPPING_BAR';
export const FREE_SHIPPING_BAR_SEARCH_FAILURE = 'FREE_SHIPPING_BAR_SEARCH_FAILURE';

export const freeShippingBarGettingAllStores = () => ({
    type: FREE_SHIPPING_BAR_GETTING_ALL_STORES,
});

export const freeShippingBarCreatingStoreJs = bool => ({
    type: FREE_SHIPPING_BAR_CREATING_STORE_JS,
    bool,
});

export const freeShippingBarModifyingBool = (attribute, bool) => ({
    type: FREE_SHIPPING_BAR_MODIFYING_BOOL,
    attribute,
    bool,
});

export const freeShippingBarModifyingNum = (attribute, bool) => ({
    type: FREE_SHIPPING_BAR_MODIFYING_NUM,
    attribute,
    bool,
});

export const freeShippingBarActuallyModifyBool = (attribute, bool) => ({
    type: FREE_SHIPPING_BAR_ACTUALLY_MODIFY_BOOL,
    attribute,
    bool,
});

export const freeShippingBarActuallyModifyNum = (attribute, num) => ({
    type: FREE_SHIPPING_BAR_ACTUALLY_MODIFY_NUM,
    attribute,
    num,
});

export const searchingFreeShippingBar = () => ({
    type: SEARCHING_FREE_SHIPPING_BAR,
});

export const freeShippingBarSearchFailure = () => ({
    type: FREE_SHIPPING_BAR_SEARCH_FAILURE,
});

export const freeShippingBarSetAllStores = json => ({
    type: FREE_SHIPPING_BAR_SET_ALL_STORES,
    json,
});

export const freeShippingBarSearchSuccess = json => ({
    type: FREE_SHIPPING_BAR_SEARCH_SUCCESS,
    json,
});

export const freeShippingBarShopNameChanged = text => ({
    type: FREE_SHIPPING_BAR_SHOP_NAME_CHANGED,
    text,
});

export function getAllFreeShippingBarStores(token) {
    return async (dispatch, getState) => {
        dispatch(freeShippingBarGettingAllStores());
        const response = await fetchApi(`free_shipping_bar_crm/get_all_stores`, { token });
        if (response.ok) {
            const json = await response.json();
            dispatch(freeShippingBarSetAllStores(json));
        } else {
            console.log('getAllFreeShippingBarStores error');
        }
    }
}

export function freeShippingBarSearchShop() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(searchingFreeShippingBar());
        const response = await fetchApi('free_shipping_bar_crm/search', {
            shop_name: state.freeShippingBar.domain,
            token: await fetchToken(),
        });
        if (response.ok) {
            const json = await response.json();
            dispatch(freeShippingBarSearchSuccess(json));
        } else {
            dispatch(freeShippingBarSearchFailure());
            dispatch(activateToast('Failed to fetch store :('));
        }
    }
}

export function freeShippingBarCreateStoreJs() {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(freeShippingBarCreatingStoreJs(true));
        const response = await fetchApi('free_shipping_bar_admin/create_store_js_from_crm', {
            shop_name: state.freeShippingBar.domain,
            token: state.freeShippingBar.attributes.access_token,
        });
        dispatch(freeShippingBarCreatingStoreJs(false));
        if (response.ok) {
            dispatch(activateToast('Success :)'));
        } else {
            dispatch(activateToast('Failed :('));
        }
    }
}

export function freeShippingBarModifyBool(attribute, bool) {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(freeShippingBarModifyingBool(attribute, true));
        const response = await fetchApi('free_shipping_bar_crm/change_shop_attr', {
            shop_name: state.freeShippingBar.domain,
            token: await fetchToken(),
            key: attribute,
            value: bool,
        });
        dispatch(freeShippingBarModifyingBool(attribute, false));
        if (response.ok) {
            dispatch(activateToast('Success :)'));
            dispatch(freeShippingBarActuallyModifyBool(attribute, bool));
        } else {
            dispatch(activateToast('Failed :('));
        }
    }
}

export function freeShippingBarModifyNum(attribute, num) {
    return async (dispatch, getState) => {
        const state = getState();
        dispatch(freeShippingBarModifyingNum(attribute, true));
        const response = await fetchApi('free_shipping_bar_crm/change_shop_attr', {
            shop_name: state.freeShippingBar.domain,
            token: await fetchToken(),
            key: attribute,
            value: state.freeShippingBar.attributes[attribute],
        });
        dispatch(freeShippingBarModifyingNum(attribute, false));
        if (response.ok) {
            dispatch(activateToast('Success :)'));
        } else {
            dispatch(activateToast('Failed :('));
        }
    }
}

