export const CHANGE_TAB = 'CHANGE_TAB';

export const changeTab = index => ({
    type: CHANGE_TAB,
    index,
});


export const tabs = [
    {
        id: 'upsell',
        content: 'Upsell Cross-sell',
    },
    {
        id: 'sticky-atc',
        content: 'Sticky ATC',
    },
    {
        id: 'free-shipping-bar',
        content: 'Free Shipping Bar',
    },
    {
        id: 'partners',
        content: 'Partners',
    },
    {
        id: 'crisis',
        content: 'Crisis',
    },
    {
        id: 'cross-app-tasks',
        content: 'Cross-app Tasks',
    },
    {
        id: 'admin-actions',
        content: 'Admin Actions',
    },
    {
        id: 'permissions',
        content: 'Permissions',
    },
];
