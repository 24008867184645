import { connect } from 'react-redux'
import Permissions from "../components/Permissions";
import {
    editUserPermissions,
    fetchPermissions,
    permissionsToggleShowOnlyActive,
    toggleUserPermission
} from "../actions/permissions";

const mapStateToProps = state => ({
    permissions: state.permissions,
});

const mapDispatchToProps = dispatch => ({
    fetchPermissions: () => dispatch(fetchPermissions()),
    setShowOnlyActive: () => dispatch(permissionsToggleShowOnlyActive()),
    editPermissions: id => () => dispatch(editUserPermissions(id)),
    togglePermission: (userId, permission) => () => dispatch(toggleUserPermission(userId, permission)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Permissions)
