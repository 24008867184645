import React from 'react';
import '../css/App.css';
import {Button, TextField, Card} from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import FileUpload from "./FileUpload";

const CrossApp = ({ crossApp, populateSegmentStickyNoZoorix, changeNumber, uploadZoorixMSuccess, uploadFailure, uploadStickyATCSuccess }) =>
    <div>
      {false && <Card>
            <span style={{backgroundColor: 'red', color: "white", fontWeight: "bold"}}>
                Please clear the segment before populating! this action just adds new ones...</span>
            <TextField label={'Segment with Sticky without Zoorix number:'}
                       onChange={changeNumber('segmentStickyNoZoorix')}
                       value={crossApp.segmentStickyNoZoorix && crossApp.segmentStickyNoZoorix.toString()}
                       type={'number'} />
            <Button onClick={populateSegmentStickyNoZoorix}>Populate</Button>
        </Card>}

      <Card>
        Upload a Zoorix-M CSV file:
        <FileUpload dropZoneType={'file'} dropZoneAccepts={'text/csv'} uploadSuccess={uploadZoorixMSuccess}
                    fileCheckFunction={file => file.name.startsWith('shopify-zoorix-bundle-kit-shops')}
                    presignedUrl={'crm/get_presigned_url_for_zoorix_m_csv'} uploadFailure={uploadFailure} />
      </Card>
      <Card>
        Upload a Sticky ATC CSV file:
        <FileUpload dropZoneType={'file'} dropZoneAccepts={'text/csv'} uploadSuccess={uploadStickyATCSuccess}
                    fileCheckFunction={file => file.name.startsWith('shopify-zoorix-sticky-atc-shops')}
                    presignedUrl={'crm/get_presigned_url_for_sticky_atc_csv'} uploadFailure={uploadFailure} />
      </Card>
    </div>;

export default CrossApp;
