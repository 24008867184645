import React from 'react';
import '../css/App.css';
import {Button, Card, TextField} from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';

const Partners = ({ partnerEmail, onPartnerEmailChange, generatePartnerLink }) =>
    <div>

        <Card title={'Partner login'}>
            <TextField label={'email'} value={partnerEmail} onChange={onPartnerEmailChange} />
            <Button onClick={generatePartnerLink}>Generate link</Button>
        </Card>

    </div>;

export default Partners;
