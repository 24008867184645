import {activateToast, fetchApi, fetchToken} from "./main";

export const SET_ADMIN_ACTION_ID = 'SET_ADMIN_ACTION_ID';
export const SET_ADMIN_TAB = 'SET_ADMIN_TAB';
export const SET_ADMIN_ACTION_LOADING = 'SET_ADMIN_ACTION_LOADING';
export const SET_ADMIN_TAB_INITIALIZING = 'SET_ADMIN_TAB_INITIALIZING';

export const setAdminActionId = id => ({
    type: SET_ADMIN_ACTION_ID,
    id,
});

export const setAdminTabInitializing = bool => ({
    type: SET_ADMIN_TAB_INITIALIZING,
    bool,
});

export const setAdminTab = json => ({
    type: SET_ADMIN_TAB,
    json,
});

export const setAdminActionLoading = (id, bool) => ({
    type: SET_ADMIN_ACTION_LOADING,
    id,
    bool,
});

export const fetchAdminTab = () => async (dispatch, getState) => {
    dispatch(setAdminTabInitializing(true));
    const token = await fetchToken();
    const res = await fetchApi('crm/get_admin_actions_tab', {token: token});
    if (res.ok) {
        const json = await res.json();
        json.permitted = true;
        dispatch(setAdminTab(json));
    } else {
        dispatch(setAdminTab({permitted: false}));
    }
    dispatch(setAdminTabInitializing(false));
}

export const manageAdminAction = (actionId, action) => async (dispatch, getState) => {
    dispatch(setAdminActionLoading(actionId, true));
    const token = await fetchToken();
    const state = getState();
    const adminAction = state.adminActions.adminActions.find(a => a.id === actionId);
    let res;
    switch (adminAction.action) {
        case "upload_zoorix_m_csv":
        case "upload_sticky_atc_csv":
            res = await fetchApi('crm/invoke_active_shops_csv', {token, action_id: actionId, act: action});
            dispatch(activateToast(res.ok ? 'Success' : 'Error'));
            dispatch(fetchAdminTab());
            break;
        default:
            dispatch(activateToast(`Unknown action: ${adminAction.action}`));
    }
    dispatch(setAdminActionLoading(actionId, false));
}
