import React, { useEffect, useMemo } from 'react';
import { useCallback } from 'react';
import '../css/App.css';
import { Button, TextField, Spinner, Checkbox, Select } from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import ReactSearchBox from 'react-search-box';
import { Descope, useSession, useUser, useDescope } from '@descope/react-sdk';
import { getSessionToken } from '@descope/react-sdk';
import { newStickyAtcHost } from '..';

const StickyAtc = ({
  stickyAtc,
  onShopNameChanged,
  onSearch,
  createStoreJs,
  modifyBoolean,
  onNumberChange,
  onSaveNumber,
  widgetTextChange,
  modifyWidgetBoolean,
  getAllStores,
  widgetDevShopsAction
}) => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const { isUserLoading } = useUser();
  const sdk = useDescope();
  const logout = sdk.logout;

  const currToken = getSessionToken();

  const permissions = useMemo(() => {
    if (currToken) {
      return sdk.getJwtPermissions(currToken)
    }

    return []
  }, [sdk, currToken])

  const canListWidgetDevShops = permissions.includes('get-widget-dev-shops')


  const widgetDevShops = stickyAtc.devShops || [];

  useEffect(() => {
    if (isAuthenticated) getAllStores(getSessionToken());
  }, [getAllStores, isAuthenticated]);

  const newAdminLink = `https://${newStickyAtcHost}/app?shopName=${stickyAtc.domain
    }&origin=config-manager&accessToken=${getSessionToken()}`;

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const token = getSessionToken();

  useEffect(() => {
    try {
      const valid = sdk.isJwtExpired(token);
      if (!valid) sdk.getRefreshToken();
    } catch (error) {
      console.error(error);
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, logout]);

  console.log('token', token);

  if (!isAuthenticated)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Descope
          flowId="sign-up-or-in"
          onSuccess={() => {
            console.log('success');
          }}
        />
      </div>
    );

  if (isSessionLoading || isUserLoading)
    return (
      <div>
        <Spinner />
      </div>
    );

  return (
    <div>
      {stickyAtc.gettingAllStores ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div>
          <button onClick={handleLogout}>Google Logout</button>

          {
            canListWidgetDevShops && (
              <div style={{
                margin: '20px 0',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px'
              }}>
                <div>
                  <Button
                    loading={stickyAtc.sendingWidgetDevShopsEmail}
                    onClick={() => {
                      widgetDevShopsAction(token);
                    }}>Get Dev Shops</Button>
                </div>


                {
                  widgetDevShops.length > 0 && (
                    <b>Widget Dev Shops</b>
                  )
                }

                {
                  widgetDevShops.map((shop) => (
                    <span>
                      {shop}
                    </span>
                  ))
                }
              </div>
            )
          }

          {stickyAtc.stores && (
            <div>
              <ReactSearchBox
                value={stickyAtc.domain}
                data={stickyAtc.stores}
                onSelect={onShopNameChanged}
                onChange={onShopNameChanged}
                placeholder="Shop domain"
              />
              <Button
                onClick={() => {
                  onSearch(getSessionToken());
                }}
                loading={stickyAtc.searchingStore}
              >
                Search
              </Button>
              {stickyAtc.shopConfig && (
                <div>
                  {stickyAtc.shopConfig.enabled && (
                    <div>
                      <a href={newAdminLink} target="_blank" rel="noreferrer">
                        Admin link ({stickyAtc.domain})
                      </a>
                    </div>
                  )}
                  <div>
                    <a
                      href={`https://${stickyAtc.domain}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visit shop
                    </a>
                  </div>
                  <Button
                    onClick={() => {
                      createStoreJs(token);
                    }}
                    loading={stickyAtc.creatingStoreJs}
                  >
                    Create store js
                  </Button>
                  {Object.keys(stickyAtc.shopConfig).map((attribute) => {
                    return (
                      <div key={attribute}>
                        {stickyAtc.booleans.includes(attribute) ? (
                          <div>
                            {stickyAtc.modifiable_booleans.includes(
                              attribute
                            ) ? (
                              <div>
                                <Checkbox
                                  label={attribute}
                                  checked={stickyAtc.shopConfig[attribute]}
                                  disabled={
                                    stickyAtc.modifyingBoolean &&
                                    stickyAtc.modifyingBoolean[attribute]
                                  }
                                  onChange={modifyBoolean(attribute, token)}
                                />
                              </div>
                            ) : (
                              <div>
                                <Checkbox
                                  label={attribute}
                                  checked={stickyAtc.shopConfig[attribute]}
                                  disabled={true}
                                />
                              </div>
                            )}
                          </div>
                        ) : stickyAtc.modifiable_numbers.includes(attribute) ? (
                          <div>
                            <TextField
                              label={attribute}
                              value={
                                stickyAtc.shopConfig[attribute] &&
                                stickyAtc.shopConfig[attribute].toString()
                              }
                              onChange={onNumberChange(attribute, token)}
                              type={'number'}
                              disabled={
                                stickyAtc.modifyingNumber &&
                                stickyAtc.modifyingNumber[attribute]
                              }
                            />
                            <Button
                              onClick={() => {
                                console.log(32);
                                const r = onSaveNumber(attribute, token)();
                                console.log(r);
                              }}
                              loading={
                                stickyAtc.modifyingNumber &&
                                stickyAtc.modifyingNumber[attribute]
                              }
                            >
                              Save {attribute}
                            </Button>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                  {stickyAtc.shopConfig && (
                    <div>
                      <h1>Widget attributes</h1>
                      {Object.keys(stickyAtc.shopConfig).map((attr) => (
                        <div key={attr}>
                          {attr === 'open_cart_drawer' && (
                            <div>
                              <Checkbox
                                label={attr}
                                checked={stickyAtc.shopConfig[attr]}
                                disabled={
                                  stickyAtc.modifyingBoolean &&
                                  stickyAtc.modifyingBoolean[attr]
                                }
                                onChange={modifyWidgetBoolean(attr, token)}
                              />
                            </div>
                          )}
                          {attr === 'css_theme' && (
                            <Select
                              label={'CSS Theme'}
                              onChange={widgetTextChange('css_theme', token)}
                              value={
                                stickyAtc.shopConfig.css_theme || 'default'
                              }
                              options={stickyAtc.cssThemesOptions}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StickyAtc;
