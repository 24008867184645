import React, {useEffect, useState} from 'react';
import '../css/App.css';
import '@shopify/polaris/dist/styles.css';
import {Button, Checkbox, Spinner} from "@shopify/polaris";
import Moment from "react-moment";

const Permissions = ({ permissions, fetchPermissions, setShowOnlyActive, editPermissions, togglePermission }) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init) return;
    fetchPermissions();
    setInit(true);
  }, [fetchPermissions, init]);

  return <div>
    {
      !permissions || permissions.initializing ?
        <Spinner /> :
        (permissions.permitted ? <div>
          <Checkbox label={'Show only active'} checked={permissions.showOnlyActive} onChange={setShowOnlyActive} />
          <table className={'permissions'}>
            <thead>
            <tr>
              <th>Email</th>
              <th>Sub</th>
              <th>Active</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Permissions</th>
            </tr>
            </thead>
            <tbody>
            {permissions.adminUsers.filter(u => permissions.showOnlyActive ? u.crm_access : true).map((user, idx) => <tr key={user.id} className={idx % 2 === 0 ? 'even' : 'odd'}>
              <td>{user.email}</td>
              <td>{user.sub}</td>
              <td>{user.crm_access ? 'yes' : 'no'}</td>
              <td><Moment format="YYYY/MM/DD HH:mm:ss">{user.created_at}</Moment></td>
              <td><Moment format="YYYY/MM/DD HH:mm:ss">{user.updated_at}</Moment></td>
              <td>
                {user.isEditingPermissions ? <div>
                  {Object.keys(permissions.permissions).map(permission => <div key={permission}>
                    <Checkbox label={permissions.permissions[permission].description}
                              disabled={user.isLoadingPermission || permission === 'manage_permissions'}
                              checked={user.permissions && user.permissions[permission]}
                              onChange={togglePermission(user.id, permission)} />
                  </div>)}
                  </div> : <div>
                    <Button onClick={editPermissions(user.id)}>Edit</Button>
                    { user.permissions && Object.entries(user.permissions).filter(e =>  e[1]).map(e => permissions.permissions[e[0]].description).sort().join(', ') }
                  </div>}
              </td>
            </tr>)}
            </tbody>
          </table>
        </div> : <div>
          <div>Sorry, you are not permitted to access this page.</div>
          <div>Please contact admin</div>
        </div>)

    }
  </div>;
}

export default Permissions;
