import {activateToast, fetchApi, fetchToken} from "./main";

export const SET_PERMISSIONS_TAB_INITIALIZING = 'SET_PERMISSIONS_TAB_INITIALIZING';
export const SET_PERMISSIONS_TAB = 'SET_PERMISSIONS_TAB';
export const PERMISSIONS_TOGGLE_SHOW_ONLY_ACTIVE = 'PERMISSIONS_TOGGLE_SHOW_ONLY_ACTIVE';
export const EDIT_USER_PERMISSIONS = 'EDIT_USER_PERMISSIONS';
export const SET_USER_PERMISSIONS_LOADING = 'SET_USER_PERMISSIONS_LOADING';
export const TOGGLE_PERMISSION = 'TOGGLE_PERMISSION';

export const setPermissionsTabInitializing = bool => ({
    type: SET_PERMISSIONS_TAB_INITIALIZING,
    bool,
});

export const permissionsToggleShowOnlyActive = () => ({
    type: PERMISSIONS_TOGGLE_SHOW_ONLY_ACTIVE,
});

export const setPermissionsTab = json => ({
    type: SET_PERMISSIONS_TAB,
    json,
});

export const editUserPermissions = id => ({
    type: EDIT_USER_PERMISSIONS,
    id,
});

export const setUserPermissionsLoading = (id, bool) => ({
    type: SET_USER_PERMISSIONS_LOADING,
    id,
    bool,
});

export const togglePermission = (id, permission) => ({
    type: TOGGLE_PERMISSION,
    id,
    permission,
});

export const fetchPermissions = () => async (dispatch, getState) => {
    dispatch(setPermissionsTabInitializing(true));
    const token = await fetchToken();
    const res = await fetchApi('crm/get_root_tab', {token: token});
    if (res.ok) {
        const json = await res.json();
        json.permitted = true;
        dispatch(setPermissionsTab(json));
    } else {
        dispatch(setPermissionsTab({permitted: false}));
    }
    dispatch(setPermissionsTabInitializing(false));
}

export const toggleUserPermission = (userId, permission) => async (dispatch, getState) => {
    dispatch(setUserPermissionsLoading(userId, true));
    const token = await fetchToken();
    const state = getState();
    const user = state.permissions.adminUsers.find(u => u.id === userId);
    const permissions = user.permissions || {};
    const res = await fetchApi('crm/set_permission', {token: token, user_id: userId,
        permission: permission, value: !permissions[permission]});
    if (res.ok) {
        dispatch(togglePermission(userId, permission));
    } else {
        dispatch(activateToast('Failed :('));
    }
    dispatch(setUserPermissionsLoading(userId, false));
}
