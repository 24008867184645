import React from 'react';
import '../css/App.css';
import {Button, TextField, Spinner, Checkbox} from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import ReactSearchBox from "react-search-box";

const FreeShippingBar = ({ freeShippingBar, onShopNameChanged, onSearch, createStoreJs, modifyBoolean, onNumberChange,
                       onSaveNumber }) =>
    <div>
        {freeShippingBar.gettingAllStores ? <div>
            <Spinner />
        </div> : <div>
            {freeShippingBar.stores && <div>
                <ReactSearchBox value={freeShippingBar.domain} data={freeShippingBar.stores} onSelect={onShopNameChanged}
                                onChange={onShopNameChanged}
                                placeholder="Shop domain" />
                <Button onClick={onSearch} loading={freeShippingBar.searchingStore}>Search</Button>
                {freeShippingBar.adminLink && <div>
                    {freeShippingBar.adminLink && freeShippingBar.attributes.enabled && <div><a href={freeShippingBar.adminLink} target="_blank" rel="noreferrer">Admin link ({freeShippingBar.domain})</a></div>}
                    <div><a href={`https://${freeShippingBar.domain}`} target="_blank" rel="noreferrer">Visit shop</a></div>
                    <Button onClick={createStoreJs} loading={freeShippingBar.creatingStoreJs}>Create store js</Button>
                    {Object.keys(freeShippingBar.attributes).map(attribute => <div key={attribute}>
                        {freeShippingBar.booleans.includes(attribute) ? <div>
                            {freeShippingBar.modifiable_booleans.includes(attribute) ? <div>
                                <Checkbox label={attribute} checked={freeShippingBar.attributes[attribute]}
                                          disabled={freeShippingBar.modifyingBoolean && freeShippingBar.modifyingBoolean[attribute]}
                                          onChange={modifyBoolean(attribute)} />
                            </div> : <div>
                                <Checkbox label={attribute} checked={freeShippingBar.attributes[attribute]}
                                          disabled={true} />
                            </div>}
                        </div> : freeShippingBar.modifiable_numbers.includes(attribute) ? <div>
                            <TextField label={attribute}
                                       value={freeShippingBar.attributes[attribute] && freeShippingBar.attributes[attribute].toString()}
                                       onChange={onNumberChange(attribute)} type={"number"}
                                       disabled={freeShippingBar.modifyingNumber && freeShippingBar.modifyingNumber[attribute]} />
                            <Button onClick={onSaveNumber(attribute)}
                                    loading={freeShippingBar.modifyingNumber && freeShippingBar.modifyingNumber[attribute]}
                            >Save {attribute}</Button>
                        </div> : <div>
                            {attribute}: {freeShippingBar.attributes[attribute]}
                        </div>}
                    </div>)}
                </div>}
            </div>}

        </div>}
    </div>;

export default FreeShippingBar;
