import { connect } from 'react-redux'
import CrossApp from "../components/CrossApp";
import {crossAppChangeNumber, invokeCsvUpdate, populateSegmentStickyNoZoorix} from "../actions/crossApp";
import {activateToast} from "../actions/main";

const mapStateToProps = state => ({
    crossApp: state.crossApp,
});

const mapDispatchToProps = dispatch => ({
    populateSegmentStickyNoZoorix: () => dispatch(populateSegmentStickyNoZoorix()),
    changeNumber: field => text => dispatch(crossAppChangeNumber(field, text)),
    uploadFailure: () => dispatch(activateToast("Upload failed")),
    uploadZoorixMSuccess: (_, json) => dispatch(invokeCsvUpdate('zoorix-m', json)),
    uploadStickyATCSuccess: (_, json) => dispatch(invokeCsvUpdate('sticky-atc', json)),
});


export default connect(mapStateToProps, mapDispatchToProps)(CrossApp)
