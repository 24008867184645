import { connect } from 'react-redux'
import {
    addScriptTag,
    booleanChange,
    createProductWebhooks,
    createStoreJs,
    deleteProductWebhooks,
    dismissToast,
    generatePartnerLink,
    hardRefresh,
    installScripts,
    moneyCssClassChange,
    numberChange,
    onShopNameChanged,
    openSendReviewRequest, packageChange,
    partnerEmailChange,
    removeScriptTag,
    reviewRequestEmailChange, saveNumber,
    searchShop,
    sendReviewRequest,
    submitSettings,
    toggleAttributes, wakeUpAriel
} from "../actions/main";
import Partners from "../components/Partners";

const mapStateToProps = state => ({
    shopName: state.main.shopName,
    searching: state.main.searching,
    searchError: state.main.searchError,
    error: state.main.error,
    adminLink: state.main.adminLink,
    bundlesAdminLink: state.main.bundlesAdminLink,
    attributes: state.main.attributes,
    showAttributes: state.main.showAttributes,
    booleans: state.main.booleans,
    boolProcessing: state.main.boolProcessing,
    gettingAllStores: state.main.gettingAllStores,
    stores: state.main.stores,
    modifiableBooleans: state.main.modifiableBooleans,
    resyncing: state.main.resyncing,
    installingScripts: state.main.installingScripts,
    toastIsActive: state.main.toastIsActive,
    toastContent: state.main.toastContent,
    addingScriptTag: state.main.addingScriptTag,
    removingScriptTag: state.main.removingScriptTag,
    creatingStoreJs: state.main.creatingStoreJs,
    generalSettings: state.main.generalSettings,
    isSubmitting: state.main.isSubmitting,
    showReviewRequestEmail: state.main.showReviewRequestEmail,
    reviewRequestEmail: state.main.reviewRequestEmail,
    sendingReviewRequest: state.main.sendingReviewRequest,
    partnerEmail: state.main.partnerEmail,
    modifiableNumbers: state.main.modifiableNumbers,
    numberProcessing: state.main.numberProcessing,
    numberError: state.main.numberError,
    packages: state.main.packages,
    packageChanging: state.main.packageChanging,
});

const mapDispatchToProps = dispatch => ({
    onShopNameChanged: text => dispatch(onShopNameChanged(text)),
    onMoneyCssClassChange: text => dispatch(moneyCssClassChange(text)),
    onReviewRequestEmailChange: text => dispatch(reviewRequestEmailChange(text)),
    onPartnerEmailChange: text => dispatch(partnerEmailChange(text)),
    onPackageChange: id => dispatch(packageChange(id)),
    onSearch: () => dispatch(searchShop()),
    submitSettings: () => dispatch(submitSettings()),
    toggleAttributes: () => dispatch(toggleAttributes()),
    deleteProductWebhooks: () => dispatch(deleteProductWebhooks()),
    createProductWebhooks: () => dispatch(createProductWebhooks()),
    hardRefresh: () => dispatch(hardRefresh()),
    installScripts: () => dispatch(installScripts()),
    addScriptTag: () => dispatch(addScriptTag()),
    removeScriptTag: () => dispatch(removeScriptTag()),
    createStoreJs: () => dispatch(createStoreJs()),
    sendReviewRequest: () => dispatch(sendReviewRequest()),
    onDismissToast: () => dispatch(dismissToast()),
    openSendReviewRequest: () => dispatch(openSendReviewRequest()),
    wakeUpAriel: () => dispatch(wakeUpAriel()),
    generatePartnerLink: () => dispatch(generatePartnerLink()),
    onBooleanChange: key => bool => dispatch(booleanChange(key, bool)),
    onNumberChange: key => number => dispatch(numberChange(key, number)),
    onSaveNumber: key => () => dispatch(saveNumber(key)),
});


export default connect(mapStateToProps, mapDispatchToProps)(Partners)
