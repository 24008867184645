import React, {useEffect, useState} from 'react';
import '../css/App.css';
import '@shopify/polaris/dist/styles.css';
import {Button, Spinner} from "@shopify/polaris";
import Moment from "react-moment";

const AdminActions = ({ fetchAdminTab, adminActions, manageAction }) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (init) return;
    fetchAdminTab();
    setInit(true);
  }, [fetchAdminTab, init]);

  return <div>
    {
      !adminActions || adminActions.initializing ?
        <Spinner /> :
        (adminActions.permitted ? <div>
          <table className={'admin-actions'}>
            <thead>
            <tr>
              <th>Action</th>
              <th>Status</th>
              <th>Invoker</th>
              <th>Payload</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Manage</th>
            </tr>
            </thead>
            <tbody>
            {adminActions.adminActions.map(adminAction => <tr key={adminAction.id} className={adminActions.actionId === adminAction.id ? 'colored' : ''}>
              <td>{adminActions.actions[adminAction.action].description}</td>
              <td>{adminAction.status}</td>
              <td>{adminAction.invoker_data?.email}</td>
              <td>{JSON.stringify(adminAction.payload)}</td>
              <td><Moment format="YYYY/MM/DD HH:mm:ss">{adminAction.created_at}</Moment></td>
              <td><Moment format="YYYY/MM/DD HH:mm:ss">{adminAction.updated_at}</Moment></td>
              <td>
                {adminAction.isLoading ? <Spinner /> : <div>
                  {['upload_zoorix_m_csv', 'upload_sticky_atc_csv'].includes(adminAction.action) && adminAction.status === 'pending' && <div>
                    <Button onClick={manageAction(adminAction.id, 'approve')} loading={adminAction.loading}>Approve</Button>
                    <Button onClick={manageAction(adminAction.id, 'abort')} loading={adminAction.loading}>Abort</Button>
                  </div>}
                </div>}
              </td>
            </tr>)}
            </tbody>
          </table>
        </div> : <div>
          <div>Sorry, you are not permitted to access this page.</div>
          <div>Please contact admin</div>
        </div>)

    }
  </div>;
}

export default AdminActions;
