import * as _ from 'lodash';
import {
    SEARCHING_FREE_SHIPPING_BAR,
    FREE_SHIPPING_BAR_ACTUALLY_MODIFY_BOOL, FREE_SHIPPING_BAR_ACTUALLY_MODIFY_NUM,
    FREE_SHIPPING_BAR_CREATING_STORE_JS,
    FREE_SHIPPING_BAR_GETTING_ALL_STORES,
    FREE_SHIPPING_BAR_MODIFYING_BOOL,
    FREE_SHIPPING_BAR_MODIFYING_NUM,
    FREE_SHIPPING_BAR_SEARCH_FAILURE,
    FREE_SHIPPING_BAR_SEARCH_SUCCESS,
    FREE_SHIPPING_BAR_SET_ALL_STORES,
    FREE_SHIPPING_BAR_SHOP_NAME_CHANGED
} from "../actions/freeShippingBar";

const freeShippingBar = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case FREE_SHIPPING_BAR_GETTING_ALL_STORES:
            newState = _.cloneDeep(state);
            newState.gettingAllStores = true;
            break;
        case FREE_SHIPPING_BAR_CREATING_STORE_JS:
            newState = _.cloneDeep(state);
            newState.creatingStoreJs = action.bool;
            break;
        case FREE_SHIPPING_BAR_MODIFYING_BOOL:
            newState = _.cloneDeep(state);
            newState.modifyingBoolean = newState.modifyingBoolean || {};
            newState.modifyingBoolean[action.attribute] = action.bool;
            break;
        case FREE_SHIPPING_BAR_MODIFYING_NUM:
            newState = _.cloneDeep(state);
            newState.modifyingNumber = newState.modifyingNumber || {};
            newState.modifyingNumber[action.attribute] = action.bool;
            break;
        case FREE_SHIPPING_BAR_ACTUALLY_MODIFY_BOOL:
            newState = _.cloneDeep(state);
            newState.attributes[action.attribute] = action.bool;
            break;
        case FREE_SHIPPING_BAR_ACTUALLY_MODIFY_NUM:
            newState = _.cloneDeep(state);
            newState.attributes[action.attribute] = action.num;
            break;
        case FREE_SHIPPING_BAR_SHOP_NAME_CHANGED:
            newState = _.cloneDeep(state);
            newState.domain = action.text;
            break;
        case SEARCHING_FREE_SHIPPING_BAR:
            newState = _.cloneDeep(state);
            newState.searchingStore = true;
            newState.adminLink = null;
            break;
        case FREE_SHIPPING_BAR_SET_ALL_STORES:
            newState = _.cloneDeep(state);
            newState.gettingAllStores = false;
            newState.stores = action.json.shop_names.map(s => ({key: s, value: s}));
            newState.booleans = _.cloneDeep(action.json.booleans);
            newState.modifiable_numbers = _.cloneDeep(action.json.modifiable_numbers);
            newState.modifiable_booleans = _.cloneDeep(action.json.modifiable_booleans);
            break;
        case FREE_SHIPPING_BAR_SEARCH_SUCCESS:
            newState = _.cloneDeep(state);
            newState.searchingStore = false;
            newState.adminLink = action.json.admin_link;
            newState.attributes = _.cloneDeep(action.json.attributes);
            break;
        case FREE_SHIPPING_BAR_SEARCH_FAILURE:
            newState = _.cloneDeep(state);
            newState.searchingStore = false;
            break;
        default:
            return state;
    }
    return newState;
};

export default freeShippingBar;
