import * as _ from 'lodash';
import {CHANGE_TAB} from "../actions/nav";

const nav = (state = {}, action) => {
    let newState = null;
    switch (action.type) {
        case CHANGE_TAB:
            newState = _.cloneDeep(state);
            newState.selectedTab = action.index;
            break;
        default:
            return state;
    }
    return newState;
};

export default nav;
