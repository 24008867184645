import {activateToast, fetchApi, fetchStickyAtcApi, fetchToken} from "./main";

export const CROSS_APP_CHANGE_NUMBER = 'CROSS_APP_CHANGE_NUMBER';
export const crossAppChangeNumber = (field, text) => ({
    type: CROSS_APP_CHANGE_NUMBER,
    field,
    text,
});

export function populateSegmentStickyNoZoorix() {
    return async (dispatch, getState) => {
        const state = getState();
        let token = await fetchToken();
        let res = await fetchApi('crm/get_installed_shops', {token: token});
        if (!res.ok) {
            console.log('populateSegmentStickyNoZoorix failed');
            dispatch(activateToast('failed!'));
            return;
        }
        let json = await res.json();
        const zoorixShops = new Set(json.shops);
        res = await fetchStickyAtcApi('sticky_atc_crm/get_installed_subscribed_shops', {token: token});
        if (!res.ok) {
            console.log('populateSegmentStickyNoZoorix failed');
            dispatch(activateToast('failed!'));
            return;
        }
        json = await res.json();
        const stickyShops = new Set(json.shop);
        const stickyMinusZoorixSet = new Set([...stickyShops].filter(x => !zoorixShops.has(x)));
        const stickyMinusZoorixArray = new Array(...stickyMinusZoorixSet);
        const chunkSize = 1000;
        for (let i = 0; i < stickyMinusZoorixArray.length; i += chunkSize) {
            const chunk = stickyMinusZoorixArray.slice(i, i + chunkSize);
            res = await fetch(`https://track.customer.io/api/v1/segments/${state.crossApp.segmentStickyNoZoorix}/add_customers`, {
                method: 'post',
                headers: {
                    'Authorization': `Basic ${btoa(`624a6daa7879cc45d95a:d2c3f3e0a216cf0b66f1`)}`,
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    ids: chunk,
                })
            })
            if (!res.ok) {
                console.log('populateSegmentStickyNoZoorix failed');
                dispatch(activateToast('failed!'));
                return;
            }
        }
        dispatch(activateToast(`Segment ${state.crossApp.segmentStickyNoZoorix} populated`));
    }
}

export function invokeCsvUpdate(appType, json) {
    return async (dispatch, getState) => {
        let token = await fetchToken();
        let res = await fetchApi('crm/send_2fa_email_to_trigger_csv_update',
          {token, app_type: appType, file_name: json.file_name, email: 'ohad@zoorix.com'});
        if (!res.ok) {
            console.log('invokeCsvUpdate failed');
            dispatch(activateToast('Failed!'));
            return;
        }
        dispatch(activateToast("Success :)"));
    }
}
